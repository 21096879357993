import { useState, useEffect } from 'react'
import { abort, reinit } from './abortController'

type TResult<T> = {
   data   : T | null,
   loading: boolean,
}

export const useAsyncFn = <T>(
   apiFunc: (...param: any) => Promise<T>,
   param  : any
): TResult<T> => {

   const [data   , setData   ] = useState <T | null> (null)
   const [loading, setLoading] = useState <boolean>  (true)
 
   useEffect(() => {

      setData (null)

      const fetchData = async () => {
         setLoading (true) 

         try {
            const result = await apiFunc (param)
            setData (result)
         } finally { setLoading (false) }
      }
 
      fetchData ()

      return () => { abort (); reinit (); }
   }, [apiFunc, param])
 
   return { data, loading }
}