import React, { FC, useCallback, useState } from "react"
import { useMatch } from "react-router-dom"
import { observer } from "mobx-react"

import { getPublicData } from "api/publicdata"
import { TPublicData   } from "models/PublicDevice"
import { useAsyncFn    } from "utils/useAsyncFn"

import { Loader } from "components/Common/Loader"
import {
   Box,
   Button,
   Heading,
   Paragraph
} from "grommet"

export const DeviceLanding : FC = observer(() => {

   const { params } = useMatch ("/h/:hex");
   const [publicData, setPublicData] = useState <TPublicData> ();

   const getPublic = useCallback (
      async (hex: string) => {
         const result = await getPublicData (hex)
         setPublicData (result)
      }, [params?.hex])

   const { loading } = useAsyncFn (getPublic, params?.hex)

   console.log (publicData)
   if (loading) return <Loader />
   else return (
      <>
         { publicData 

            ? <Box pad = "large">
               Данные контроллера
               <Heading>

                  { 
                     `"${ publicData.device?.name ??
                          publicData.hardware?.name ??
                          publicData.hardware?.typename }"`
                  }

               </Heading>

               { publicData.error_text

               ? (
                  <Box
                     margin = { { vertical: 'medium' } }
                  >
                     <Heading
                        level = { 3 }
                        color = "white"
                        style = { {
                           background: '#e74c3c',
                           padding   : '24px'
                        } }
                     >
                           Данное устройство не привязано к проекту
                     </Heading>
                  </Box>
               ) : (
                  <>
                     <Paragraph>
                        Проект: <b>{ publicData.device?.projectname }</b>

                        { publicData.device?.projectremark &&
                           <>
                              <br />
                              Описание проекта: { publicData.device?.projectremark }
                              <br />
                           </>
                        }

                     </Paragraph>
                     <Paragraph>
                        <Button
                           primary
                           fill   = 'horizontal'
                           href   = { `https://cloud.uniport.pro/projects/${publicData.device?.projectid}` }
                           target = '_blank'
                           label  = 'Открыть проект'
                           style  = { {
                              textAlign: 'center'
                           } }
                        />
                     </Paragraph>
                  </>
               ) }

               <Paragraph>
                  { `Описание: ${ publicData.device?.remark ??
                                  publicData.hardware?.description ??
                                  publicData.hardware?.typedescription ??
                                  '' }` }
                  <br />
                  { `Создан: ${ publicData.device?.dset 
                              ? new Date (publicData.device?.dset).toLocaleString () 
                              : publicData.hardware?.dset &&
                                 new Date (publicData.hardware?.dset).toLocaleString () }` }
               </Paragraph>

               <Paragraph>
                  Тип: { publicData.hardware?.typename }
                  <br />
                  Описание типа: { publicData.hardware?.typedescription }
               </Paragraph>

               { publicData.device?.id_controlpanel &&

                  <Paragraph>
                     <Button
                        primary
                        fill   = 'horizontal'
                        href   = { `https://cp.uniport.pro/${publicData.device?.projectid}/${publicData.device?.id_controlpanel}` }
                        target = '_blank'
                        label  = 'Контрольная панель'
                        style  = { {
                           textAlign: 'center'
                        } }
                     />
                  </Paragraph>

               }

            </Box>
         : (
            <Box
               background = '#e74c3c'
               pad        = 'medium'
               align      = "center"
            >
               <Heading
                  level = { 3 }
                  color = "white"
               >
                     Этот идентификатор не привязан ни к устройству
               </Heading>
            </Box>
         )
         }
      </>
   )  
});