import React, { useContext } from 'react'

import { Box, Button, Select, Tip } from 'grommet'
import {
   Save,
   Lock,
   Unlock,
   Menu,
   Close,
   Book,
   Previous,
   History,
   LinkNext,
} from 'grommet-icons'

import { EditorContext, useEditor } from '../EditorContextProvider'
import { observer } from 'mobx-react'
import { TEditorContext, TEditorHook } from 'models/Editor'

import { useAsyncFn } from 'utils/useAsyncFn'
import { getSoftwareHistory } from 'api/softwarecode'

type TEditorPanelProps = {
   openMenu          : TEditorContext['openMenu'],
   readOnly          : TEditorContext['readOnly'],
   historyItem       : TEditorContext['historyItem'],
   setSoftwareCode   : TEditorContext['setSoftwareCode'],
   setOpenMenu       : TEditorContext['setOpenMenu'],
   setOpenPanels     : TEditorContext['setOpenPanels'],
   setHistory        : TEditorContext['setHistory'],
   setHistoryItem    : TEditorContext['setHistoryItem'],
   setReadOnly       : TEditorContext['setReadOnly'],
   services          : TEditorContext['services'],
   compilationService: TEditorContext['compilationService'],
   idSoftwareCode    : TEditorContext['idSoftwareCode'],
}

type TEditorPanelHook = {
   makeAlternate: TEditorHook['makeAlternate'],
   saveCode     : TEditorHook['saveCode'],
   compilateCode: TEditorHook['compilateCode'],
   emValidation : TEditorHook['emValidation'],
   plcValidation: TEditorHook['plcValidation'],
}

export const DiffEditorPanel = observer (() => {

   const {
      openMenu,
      readOnly,
      historyItem,
      setSoftwareCode,
      setOpenPanels,
      setOpenMenu,
      setHistory,
      setHistoryItem,
      setReadOnly,
      idSoftwareCode,
   } : TEditorPanelProps = useContext (EditorContext)

   const { saveCode } : TEditorPanelHook = useEditor ()
   const { data, loading } = useAsyncFn (getSoftwareHistory, idSoftwareCode)

   return (

      <Box
         round      = { { size: 'xsmall', corner: 'top' } }
         direction  = 'row'
         background = 'backgroundSideBar'
         border     = { { side: 'bottom' } }
         justify    = 'between'
      >
         <Box
            align     = 'center'
            justify   = 'start'
            direction = 'row'
         >
            <Button
               tip     = 'Выйти из истории'
               onClick = { () => setHistory (false) }
               icon    = { <Previous /> }
            />

            { openMenu ? (

               <Button
                  tip     = 'Закрыть меню'
                  onClick = { () => setOpenMenu (false) }
                  icon    = { <Close /> }
               />
            ) : (

               <Button
                  tip     = 'Открыть меню'
                  onClick = { () => setOpenMenu (true) }
                  icon    = { <Menu /> }
               />
            ) }

            <Button
               tip     = 'Сохранить (Ctrl+S, F2)'
               onClick = { saveCode }
               icon    = { <Save /> }
            />

            { (!loading && data?.length > 0) &&

               <>
                  <Tip content = 'Выберите пункт истории'>
                     <Box
                        style  = { { whiteSpace: 'nowrap' } }
                        margin = { { left: 'small' } }
                     >
                        <Select
                           placeholder = 'Пункт истории не выбран'
                           options  = { data }
                           value    = { historyItem }
                           labelKey = { option => 
                              <span
                                 style = { { 
                                    whiteSpace: 'nowrap',
                                    padding   : '4px 11px'
                                 } }
                              >
                                 { `${ new Date (option.dset).toLocaleString () } ${ option.author }` }
                              </span> }
                           valueKey = "id_log"
                           onChange = { ({ option }) => setHistoryItem (option) }
                           style    = { { padding: '4px 11px' } }
                           onMore   = { () => {console.log (111)} }
                        />
                     </Box>
                  </Tip>

                  <Button
                     tip     = 'Восстановить из истории'
                     onClick = { () => setSoftwareCode (historyItem?.softwarecode) }
                     icon    = { <LinkNext /> }
                  />
               </>
            }

         </Box>

         <Box
            align     = 'center'
            justify   = 'end'
            direction = 'row'
         >

            <Button
               tip     = 'Выйти из истории'
               onClick = { () => {
                  setHistory (false)
                  setOpenPanels (true)
               } }
               icon    = { <History /> }
            />

            <Button
               tip    = 'Описание языка'
               href   = { '//docs.google.com/document/d/140IwZm7UJBBXHfge8G4U5g_PGY2vYpSDE9cHeIPrp0s' }
               target = '_blank'
               icon   = { <Book /> }
            />

            { readOnly ? (

               <Button
                  tip     = 'Разрешить редактирование'
                  onClick = { () => setReadOnly (false) }
                  icon    = { <Lock /> }
               />
            ) : (

               <Button
                  tip     = 'Запретить редактирование'
                  onClick = { () => setReadOnly (true) }
                  icon    = { <Unlock /> }
               />
            ) }
         </Box>
      </Box>
   )
})
