import React, { useEffect, useMemo, useState } from "react"
import { observer  } from "mobx-react"
import { useParams } from "react-router-dom"

import { TUnitType } from "models/UnitType"
import { TSlot     } from "models/Slot"
import { TUnit     } from "models/Unit"

import { unitStore } from "store/UnitStore"
import { slotStore } from "store/SlotStore"
import { deviceLinkStore } from "store/DeviceLinkStore"

import { getUnitType } from "api/unittype"
import { createDeviceLinkItem } from "api/devicelink"

import {
   Box,
   Heading,
   Form,
   FormField,
   TextInput,
   Button,
   Select
} from "grommet"
import { toast } from "react-toastify"

type TCreateLinkEvent = {
   value: {
      id_unitslots: number[];
      remark: string;
   };
};

type TOptionItem = {
   slot: TSlot;
   unit: TUnit;
}

export const LinkCreate = observer ((props: { 
   slot?: TSlot,
   setShow
}) => {

   const { idDevice } = useParams()
   const [avalUnits, setAval  ] = useState <TOptionItem[]> ()
   const [value    , setValue ] = useState <TOptionItem> ()
   const [remark   , setRemark] = useState <string> ()
   const [types    , setTypes ] = useState <TUnitType[]> ()

   const createLink = async (event: TCreateLinkEvent) => {

      if (idDevice) { 

         const response = await createDeviceLinkItem ({
            id_unitslots: [props.slot.id_unitslot, value?.slot.id_unitslot],
            remark: event.value.remark
         })

         if (!response.error_text) {

            slotStore.loading = true

            await unitStore.getUnits (Number (idDevice))
            await deviceLinkStore.getDeviceLinks (Number (idDevice))
            await slotStore.getAllSlots (unitStore.units)
            .then(() => slotStore.loading = false)

            toast.success (`Связь ${response.id_slotlink ?? ""} создана`)
            props.setShow (false)

         } else toast.error (response.error_text)
      }
   }

   const getAval = async () => {

      const AvalUnits: TOptionItem[] = [];
      const AvalSlots = await slotStore.getAvaliableSlotsForLink (props.slot?.id_unitslot)
      
      AvalSlots?.map (slot => {

         if (slot != undefined && !unitStore.loading) {

            AvalUnits.push ({ 
               "slot": slot,
               "unit": unitStore.units
               ?.find (unit => unit.id_unit == slot.id_unit) 
            })
         }
      })

      setAval(AvalUnits)
   }

   useMemo(() => { getAval() }, [])

   useEffect(() => {

      const unitTypes   = []
      const noNameUnits = avalUnits
      ?.filter (obj => !obj.unit?.name || !obj.unit?.remark)

      noNameUnits?.forEach (obj => {
         getUnitType (Number (obj.unit.id_unittype))
         .then(result => unitTypes.push (result))
      })

      setTypes (unitTypes)
   }, [avalUnits])

   useEffect(() => setRemark (value?.unit.remark), [value])

   return (
      <Box
         fill
         pad    = "medium"
         margin = "medium"
      >
         <Heading margin = { { top:"10px" } }>
            Создать связь
         </Heading>

         <Form onSubmit = { createLink }>
            <Box
               width  = "large"
               margin = { { bottom: "large" } }
            >

               { avalUnits &&

                  <FormField
                     name  = "unit"
                     label = "Название компонента"
                  >
                     <Select
                        name        = "avalUnits"
                        placeholder = "Выберите компонент"
                        labelKey    = { option =>
                           `Компонент: ${option.unit?.name ||
                           types.find(type => type.id_unittype == option.unit.id_unittype)?.name}, слот: ${option?.slot.name}` }
                        valueKey = { option => option?.slot }
                        options  = { avalUnits }
                        value    = { value }
                        onChange = { ({ option }) => setValue (option) }
                     />
                     { value &&

                        <Box
                           pad       = "small"
                           direction = "row"
                        >
                           <span>Описание:&nbsp;</span>
                           <span style = { { whiteSpace: "pre-line" } }>
                              { remark || types.find (type => type.id_unittype == value.unit.id_unittype)?.description }
                           </span>
                        </Box>
                     }
                  </FormField>
               }

               <FormField
                  name  = "remark"
                  label = "Описание связи"
               >
                  <TextInput name = "remark" />
               </FormField>

            </Box>

            <Button
               primary
               type  = "submit"
               label = "Создать"
            />
            <Button
               label   = "Отмена"
               margin  = { { left: "medium" } }
               onClick = { () => props.setShow (false) }
            />
         </Form>
      </Box>
   )
})
