import { makeAutoObservable } from "mobx"
import { TUnitType          } from "models/UnitType"
import { errorHandler       } from "utils/errorHandler"
import { getUnitTypeList    } from "api/unittype"

class UnitTypeStore {

   loading            = true
   types: TUnitType[] = []

   constructor () {
      makeAutoObservable(this);
   }

   setUnitTypes (types: TUnitType[]) {

      this.types   = types
      this.loading = false

      //console.log ("Units loaded"); 
   }

   getUnitTypes = async () => {

      try {
         this.loading = true

         const result = await getUnitTypeList ()
         this.setUnitTypes (result)

      } catch (err) {
         errorHandler (err)
      }
   }
}

export const unitTypeStore = new UnitTypeStore ()
